/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { getUserToken } from '../redux/app/actions'
import { resetApp } from '../redux/app/reducers'
import store from '../redux/store'
import { refreshAuthTokens } from './auth'
const apiUrl = process.env.REACT_APP_API_ENDPOINT as string
const apiVersion = process.env.REACT_APP_API_VERSION as string
const apiEndPoint = `${apiUrl}/${apiVersion}`

const axiosInstance: AxiosInstance = axios.create({
  baseURL: apiEndPoint,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')?.[1] || ''))
  } catch (e) {
    return null
  }
}

function handleError(error: any): never {
  const responseStatus = error?.response?.status
  if (responseStatus === 401) {
    store.dispatch(resetApp())
    localStorage.clear()
    throw new Error('Unauthorized')
  }
  if (responseStatus >= 400 && responseStatus < 600) {
    throw new Error(error?.response?.data?.message || 'Something went wrong')
  }
  throw new Error(error?.response?.data?.message || 'Something went wrong')
}

async function get(url: string, params: Record<string, any> = {}): Promise<any> {
  const isToken = getUserToken()?.accessToken || ''
  const config: AxiosRequestConfig = {
    params,
    headers: {
      ...(isToken && { Authorization: isToken }),
    },
  }

  try {
    const response: AxiosResponse = await axiosInstance.get(url, config)
    return response.data
  } catch (error) {
    handleError(error)
  }
}

async function post(url: string, data: any = {}, isFile = false): Promise<any> {
  const isToken = getUserToken()?.accessToken || ''
  const headers = {
    ...(isToken && { Authorization: isToken }),
    ...(isFile ? { 'Content-Type': 'multipart/form-data' } : { 'Content-Type': 'application/json' }),
  }

  try {
    const response: AxiosResponse = await axiosInstance.post(url, isFile ? data : JSON.stringify(data), { headers })
    return response.data
  } catch (error) {
    handleError(error)
  }
}

async function put(url: string, data: any = {}, isFile = false): Promise<any> {
  const isToken = getUserToken()?.accessToken || ''
  const headers = {
    ...(isToken && { Authorization: isToken }),
    ...(isFile ? { 'Content-Type': 'multipart/form-data' } : { 'Content-Type': 'application/json' }),
  }

  try {
    const response: AxiosResponse = await axiosInstance.put(url, isFile ? data : JSON.stringify(data), { headers })
    return response.data
  } catch (error) {
    handleError(error)
  }
}

async function patch(url: string, data: any = {}): Promise<any> {
  const isToken = getUserToken()?.accessToken || ''
  const config: AxiosRequestConfig = {
    headers: {
      ...(isToken && { Authorization: isToken }),
    },
  }

  try {
    const response: AxiosResponse = await axiosInstance.patch(url, data, config)
    return response.data
  } catch (error) {
    handleError(error)
  }
}

async function deleteReq(url: string, params: Record<string, any> = {}): Promise<any> {
  const isToken = getUserToken()?.accessToken || ''
  const config: AxiosRequestConfig = {
    params,
    headers: {
      ...(isToken && { Authorization: isToken }),
    },
  }

  try {
    const response: AxiosResponse = await axiosInstance.delete(url, config)
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export default {
  get,
  post,
  put,
  patch,
  delete: deleteReq,
}
