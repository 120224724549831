import { FC } from 'react'
import { Suspense, lazy } from 'react'
import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom'
import PageLoader from '../components/PageLoader'
import { ROUTE_PATHS } from './constants'
import CheckAuth from '../hoc/checkAuth'
import LayoutWrapper from '../layout'

const Dashboard = lazy(() => import('../pages/Dashboard'))

const Auth = lazy(() => import('../pages/Auth'))
const PageNotFound = lazy(() => import('../pages/404'))
const CreateStore = lazy(() => import('../pages/CreateStore'))
const Logout = lazy(() => import('../pages/Logout'))
// product
const CreateProduct = lazy(() => import('../pages/Catalogue/Product/CreateProduct'))
const Products = lazy(() => import('../pages/Catalogue/Product'))
const ProductDetails = lazy(() => import('../pages/Catalogue/Product/ProductDetails'))
const EditProduct = lazy(() => import('../pages/Catalogue/Product/CreateProduct'))
// category
const Categories = lazy(() => import('../pages/Catalogue/Categories'))
const CreateCategory = lazy(() => import('../pages/Catalogue/Categories/CreateCategory'))
const EditCategory = lazy(() => import('../pages/Catalogue/Categories/CreateCategory'))
const CategoryDetails = lazy(() => import('../pages/Catalogue/Categories/CategoryDetails'))
//Inventory
const Inventory = lazy(() => import('../pages/Catalogue/Inventory'))
// const InventoryStock = lazy(() => import('../pages/Catalogue/Inventory/Stock'))
// const InventoryMovements = lazy(() => import('../pages/Catalogue/Inventory/Movements'))
// const InventoryAlerts = lazy(() => import('../pages/Catalogue/Inventory/Alerts'))
// const InventoryReports = lazy(() => import('../pages/Catalogue/Inventory/Reports'))
// media
const Media = lazy(() => import('../pages/Media'))
const AddMedia = lazy(() => import('../pages/Media/AddMedia'))
// settings
const Settings = lazy(() => import('../pages/Settings'))
//Quick Menu
const QuickCreateProduct = lazy(() => import('../pages/Catalogue/Product/QuickCreateProduct'))
//customers
const Customers = lazy(() => import('../pages/Customers'))
const CreateCustomer = lazy(() => import('../pages/Customers/CreateCustomer'))
const CustomerDetails = lazy(() => import('../pages/Customers/CustomerDetails'))
//Sales
const Orders = lazy(() => import('../pages/Sales/Orders'))
const AbandonedCarts = lazy(() => import('../pages/Sales/Abandoned'))
//Paymnents
const Payments = lazy(() => import('../pages/Payments'))
//Reports
const SalesReports = lazy(() => import('../pages/Report/Sales'))
const ProductReports = lazy(() => import('../pages/Report/Product'))
const TrafficReports = lazy(() => import('../pages/Report/Traffic'))
//Teams
const Teams = lazy(() => import('../pages/Teams'))
//Profile
const Profile = lazy(() => import('../pages/UserProfile'))
//Notifications
const Notifications = lazy(() => import('../pages/Notifications'))
// Store Reviews
const StoreReviews = lazy(() => import('../pages/Reviews'))

const AppRouter: FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<PageLoader />}>
        <LayoutWrapper>
          <Routes>
            {/* Protected routes */}
            <Route
              path={ROUTE_PATHS.HOME}
              element={
                <CheckAuth>
                  <Navigate to={ROUTE_PATHS.DASHBOARD} replace={true} />
                </CheckAuth>
              }
            />
            <Route
              path={ROUTE_PATHS.DASHBOARD}
              element={
                <CheckAuth>
                  <Dashboard />
                </CheckAuth>
              }
            />
            <Route
              path={ROUTE_PATHS.CREATE_STORE}
              element={
                <CheckAuth>
                  <CreateStore />
                </CheckAuth>
              }
            />

            {/* Product Route */}
            <Route
              path={ROUTE_PATHS.PRODUCT_CREATE}
              element={
                <CheckAuth>
                  <CreateProduct />
                </CheckAuth>
              }
            />
            <Route
              path={`${ROUTE_PATHS.PRODUCT_EDIT}/:id`}
              element={
                <CheckAuth>
                  <EditProduct />
                </CheckAuth>
              }
            />
            <Route
              path={`${ROUTE_PATHS.PRODUCT_DETAILS}/:id`}
              element={
                <CheckAuth>
                  <ProductDetails />
                </CheckAuth>
              }
            />
            <Route
              path={ROUTE_PATHS.PRODUCTS}
              element={
                <CheckAuth>
                  <Products />
                </CheckAuth>
              }
            />
            {/* Category Route */}
            <Route
              path={ROUTE_PATHS.CATEGORY_CREATE}
              element={
                <CheckAuth>
                  <CreateCategory />
                </CheckAuth>
              }
            />
            <Route
              path={`${ROUTE_PATHS.CATEGORY_EDIT}/:id`}
              element={
                <CheckAuth>
                  <EditCategory />
                </CheckAuth>
              }
            />
            <Route
              path={`${ROUTE_PATHS.CATEGORY_DETAILS}/:id`}
              element={
                <CheckAuth>
                  <CategoryDetails />
                </CheckAuth>
              }
            />
            <Route
              path={ROUTE_PATHS.CATEGORIES}
              element={
                <CheckAuth>
                  <Categories />
                </CheckAuth>
              }
            />

            {/* Inventory Route */}
            <Route
              path={ROUTE_PATHS.INVENTORY}
              element={
                <CheckAuth>
                  <Inventory />
                </CheckAuth>
              }
            />

            {/* Media Route */}
            <Route
              path={ROUTE_PATHS.ADD_MEDIA}
              element={
                <CheckAuth>
                  <AddMedia />
                </CheckAuth>
              }
            />
            <Route
              path={ROUTE_PATHS.MEDIA}
              element={
                <CheckAuth>
                  <Media />
                </CheckAuth>
              }
            />

            {/* Settings Route */}
            <Route
              path={ROUTE_PATHS.SETTINGS}
              element={
                <CheckAuth>
                  <Settings />
                </CheckAuth>
              }
            />

            {/* Quick Menu */}
            <Route
              path={ROUTE_PATHS.QUICK_CREATE_PRODUCT}
              element={
                <CheckAuth>
                  <QuickCreateProduct />
                </CheckAuth>
              }
            />

            {/* Customers */}
            <Route
              path={ROUTE_PATHS.CUSTOMERS}
              element={
                <CheckAuth>
                  <Customers />
                </CheckAuth>
              }
            />
            <Route
              path={ROUTE_PATHS.CUSTOMER_CREATE}
              element={
                <CheckAuth>
                  <CreateCustomer />
                </CheckAuth>
              }
            />
            <Route
              path={`${ROUTE_PATHS.CUSTOMER_EDIT}/:id`}
              element={
                <CheckAuth>
                  <CreateCustomer />
                </CheckAuth>
              }
            />
            <Route
              path={`${ROUTE_PATHS.CUSTOMER_DETAILS}/:id`}
              element={
                <CheckAuth>
                  <CustomerDetails />
                </CheckAuth>
              }
            />

            {/* Sales */}
            <Route
              path={ROUTE_PATHS.ORDERS}
              element={
                <CheckAuth>
                  <Orders />
                </CheckAuth>
              }
            />
            <Route
              path={ROUTE_PATHS.ABANDONED_CARTS}
              element={
                <CheckAuth>
                  <AbandonedCarts />
                </CheckAuth>
              }
            />

            {/* Reports */}
            <Route
              path={ROUTE_PATHS.REPORTS_SALES}
              element={
                <CheckAuth>
                  <SalesReports />
                </CheckAuth>
              }
            />
            <Route
              path={ROUTE_PATHS.REPORTS_PRODUCTS}
              element={
                <CheckAuth>
                  <ProductReports />
                </CheckAuth>
              }
            />
            <Route
              path={ROUTE_PATHS.REPORTS_TRAFFIC}
              element={
                <CheckAuth>
                  <TrafficReports />
                </CheckAuth>
              }
            />

            {/* Teams */}
            <Route
              path={ROUTE_PATHS.TEAMS}
              element={
                <CheckAuth>
                  <Teams />
                </CheckAuth>
              }
            />

            {/* Profile */}
            <Route
              path={ROUTE_PATHS.PROFILE}
              element={
                <CheckAuth>
                  <Profile />
                </CheckAuth>
              }
            />
            {/* Notifications */}
            <Route
              path={ROUTE_PATHS.NOTIFICATIONS}
              element={
                <CheckAuth>
                  <Notifications />
                </CheckAuth>
              }
            />
            {/* Store Reviews */}
            <Route
              path={ROUTE_PATHS.STORE_REVIEWS}
              element={
                <CheckAuth>
                  <StoreReviews />
                </CheckAuth>
              }
            />
            {/* Payments */}
            <Route
              path={ROUTE_PATHS.PAYMENTS}
              element={
                <CheckAuth>
                  <Payments />
                </CheckAuth>
              }
            />

            {/* Public Routes */}

            <Route path={ROUTE_PATHS.AUTH} element={<Auth />} />
            <Route path={ROUTE_PATHS.LOGOUT} element={<Logout />} />

            {/* 404 */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </LayoutWrapper>
      </Suspense>
    </BrowserRouter>
  )
}

export default AppRouter
