import React from 'react'
import './index.css'
import { ConfigProvider } from 'antd'
import RootRoutes from './routes'
import store from './redux/store'
import { Provider } from 'react-redux'

const App: React.FC = () => {
  return (
    <ConfigProvider
      theme={{
        // algorithm: [theme.darkAlgorithm],
        token: {
          fontFamily: '"DM Sans", sans-serif',
          colorPrimary: '#b8131b',
        },
      }}
    >
      <Provider store={store}>
        <RootRoutes />
      </Provider>
    </ConfigProvider>
  )
}

export default App
