import { Spin } from 'antd'
import React from 'react'

const PageLoader: React.FC = () => {
  return (
    <div
      style={{
        height: '100vh',
        padding: '20px 50px',
        textAlign: 'center',
        background: '#26262c',
      }}
    >
      <Spin />
    </div>
  )
}

export default PageLoader
