// authUtils.ts
import Cookies from 'js-cookie'
import api from '../utils/api'
import { setAppState, resetApp } from '../redux/app/reducers'
import { setUser, resetUser } from '../redux/user/reducers'
import { AppState, LayoutType } from '../redux/app/types'
import store from '../redux/store'

const ACCESS_TOKEN_KEY = 'access_token'
const REFRESH_TOKEN_KEY = 'refresh_token'

export const setAuthTokens = (accessToken: string, refreshToken: string) => {
  Cookies.set(ACCESS_TOKEN_KEY, accessToken, { secure: true, sameSite: 'strict' })
  Cookies.set(REFRESH_TOKEN_KEY, refreshToken, { secure: true, sameSite: 'strict' })
}

export const getAccessToken = () => Cookies.get(ACCESS_TOKEN_KEY)
export const getRefreshToken = () => Cookies.get(REFRESH_TOKEN_KEY)

export const clearAuthTokens = () => {
  Cookies.remove(ACCESS_TOKEN_KEY)
  Cookies.remove(REFRESH_TOKEN_KEY)
}

export const refreshAuthTokens = async () => {
  const refreshToken = getRefreshToken()
  if (!refreshToken) {
    throw new Error('No refresh token available')
  }

  try {
    const response = await api.get(`/auth/refresh-token?token=${refreshToken}`)
    const { access_token, refresh_token, user, selected_store, stores } = response

    setAuthTokens(access_token, refresh_token)

    const appState: AppState = {
      isAuth: true,
      isAppLoading: false,
      token: {
        accessToken: access_token,
        refreshToken: refresh_token,
      },
      selectedStore: selected_store,
      stores: stores,
      layout: {
        type: LayoutType.SIDEBAR,
        isMobile: false,
      },
    }
    store.dispatch(setUser(user))
    store.dispatch(setAppState(appState))

    return true
  } catch (error) {
    console.error('Failed to refresh token:', error)
    clearAuthTokens()
    return false
  }
}

export const logoutUser = async () => {
  clearAuthTokens()
  store.dispatch(resetUser())
  store.dispatch(resetApp())
}
