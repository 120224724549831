import React from 'react'
import { Layout } from 'antd'

interface Props {
  children: React.ReactNode
}

const FullPageLayout: React.FC<Props> = ({ children }) => {
  return (
    <Layout style={{ minHeight: '100vh' }} className="full-page-layout">
      {children}
    </Layout>
  )
}

export default FullPageLayout
