// Definition: Types for app state
export interface AppState {
  isAuth: boolean
  isAppLoading: boolean
  token: Token
  stores: Store[]
  selectedStore: SelectedStore
  layout: Layout
}

export enum LayoutType {
  FULL = 'full',
  MINI = 'mini',
  SIDEBAR = 'sidebar',
}

export enum DEVICE {
  MOBILE = 'MOBILE',
  DESKTOP = 'DESKTOP',
  TABLET = 'TABLET',
}

interface Layout {
  type: LayoutType
  isMobile: boolean
}

interface Token {
  accessToken: string
  refreshToken: string
}

export interface Store {
  id: string
  store_id: string
  is_active: boolean
  role: number
  store_domain: string
  store_type: string
  metaData: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }
}

export interface SelectedStore {
  id: string
  store_id: string
  is_active: boolean
  role: number
  store_domain: string
  store_type: string
  metaData: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }
}
