import { FC } from 'react'
import { Typography, ConfigProvider, theme, Image, Space } from 'antd'
import { Link } from 'react-router-dom'

interface BrandLogoProps {
  collapsed?: boolean
}

const { Title } = Typography

const BrandLogo: FC<BrandLogoProps> = ({ collapsed = false }) => {
  const { token } = theme.useToken()
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: token.colorPrimary,
          fontFamily: 'Noto Sans',
          fontSize: 16,
        },
      }}
    >
      <Link to="/">
        {collapsed ? (
          <Image
            src="https://res.cloudinary.com/dkvcuqr2o/image/upload/v1726815885/shopap/shopap-logo-transparent_ywuldo.png"
            height={64}
            width={64}
            preview={false}
          />
        ) : (
          <Space direction="horizontal" align="center">
            <Image
              src="https://res.cloudinary.com/dkvcuqr2o/image/upload/v1726815885/shopap/shopap-logo-transparent_ywuldo.png"
              height={55}
              width={64}
              preview={false}
            />
            <Title
              level={2}
              style={{ color: token.colorPrimary, fontFamily: 'Noto Sans', padding: '5px 0px', margin: 0 }}
            >
              <span>Shopap</span>
            </Title>
          </Space>
        )}
      </Link>
    </ConfigProvider>
  )
}

export default BrandLogo
