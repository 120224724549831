// src/redux/inventory/index.ts

import inventoryReducer, {
  fetchInventory,
  adjustStock,
  transferStock,
  getAlerts,
  resolveAlert,
  dismissAlert,
  getLowStockReport,
  getStockValueReport,
  getMovementsReport,
  exportReport,
  setSelectedItem,
  clearSelectedItem,
} from './inventorySlice'

export {
  fetchInventory,
  adjustStock,
  transferStock,
  getAlerts,
  resolveAlert,
  dismissAlert,
  getLowStockReport,
  getStockValueReport,
  getMovementsReport,
  exportReport,
  setSelectedItem,
  clearSelectedItem,
}

export default inventoryReducer
