import React from 'react'
import { useSelector } from 'react-redux'
import { getAppLayout } from '../redux/app/actions'
import { LayoutType } from '../redux/app/types'
import FullPageLayout from './FullPage'
import SidebarLayout from './SideBar'

interface Props {
  children: React.ReactNode
}

const LayoutWrapper: React.FC<Props> = ({ children }) => {
  const layout = useSelector(getAppLayout)

  switch (layout.type) {
    case LayoutType.FULL:
      return <FullPageLayout>{children}</FullPageLayout>
    case LayoutType.SIDEBAR:
      return <SidebarLayout>{children}</SidebarLayout>
    default:
      return null
  }
}

export default LayoutWrapper
