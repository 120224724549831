// RequireAuth.tsx
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { getAppAuth } from '../redux/app/actions'
import { refreshAuthTokens, getRefreshToken } from '../utils/auth'
import PageLoader from '../components/PageLoader'

interface Props {
  children: React.ReactNode
}

const RequireAuth: React.FC<Props> = ({ children }) => {
  const isAuthenticated = useSelector(getAppAuth)
  const dispatch = useDispatch()
  const [isChecking, setIsChecking] = useState(true)

  useEffect(() => {
    const checkAuth = async () => {
      if (!isAuthenticated && getRefreshToken()) {
        await refreshAuthTokens()
      }
      setIsChecking(false)
    }

    checkAuth()
  }, [isAuthenticated, dispatch])

  if (isChecking) {
    return <PageLoader />
  }

  if (!isAuthenticated) {
    return <Navigate to="/auth" />
  }

  return <React.Fragment>{children}</React.Fragment>
}

export default RequireAuth
