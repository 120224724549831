import React from 'react'
import { Row, Col, QRCode } from 'antd'
import { useSelector } from 'react-redux'
import { getAppState } from '../redux/app/actions'

const storeFrontUrl = process.env.REACT_APP_STOREFRONT_APP || 'https://shopap.in/'
const qrIconUrl =
  'https://res.cloudinary.com/dkvcuqr2o/image/upload/v1726815885/shopap/shopap-logo-transparent_ywuldo.png'

interface Props {
  url?: string
  size?: number
  iconSize?: number
  type?: 'canvas' | 'svg'
}

const StoreQRCode: React.FC<Props> = ({ url, size = 250, iconSize = 80, type = 'canvas' }) => {
  const selectedStore = useSelector(getAppState).selectedStore
  const storeUrl = url ? url : `${storeFrontUrl}/${selectedStore.store_domain}`

  return (
    <>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: `${size + 50}px`,
        }}
      >
        <Col span={24}>
          <QRCode
            style={{ margin: 'auto' }}
            value={storeUrl}
            size={size}
            type={type}
            icon={qrIconUrl}
            iconSize={iconSize}
          />
          <p style={{ margin: '10px 0px', textAlign: 'center', fontWeight: 'bold' }}>
            Scan this QR to visit <span style={{ color: 'red' }}>{storeUrl}</span> store!
          </p>
        </Col>
      </Row>
    </>
  )
}

export default StoreQRCode
