/* eslint-disable @typescript-eslint/no-explicit-any */
// src/api/inventory.ts

import api from '../../../utils/api'
import { InventoryItem, InventoryTransaction, StockTransferRequest } from './types'

export const inventoryApi = {
  // Inventory CRUD
  listInventory: async (page = 1, limit = 10, filters?: any) => {
    return await api.get('/inventory', { params: { page, limit, ...filters } })
  },

  getInventory: async (id: string) => {
    return await api.get(`/inventory/${id}`)
  },

  createInventory: async (data: Partial<InventoryItem>) => {
    return await api.post('/inventory', data)
  },

  updateInventory: async (id: string, data: Partial<InventoryItem>) => {
    return await api.put(`/inventory/${id}`, data)
  },

  deleteInventory: async (id: string) => {
    return await api.delete(`/inventory/${id}`)
  },

  // Stock Operations
  adjustStock: async (data: Partial<InventoryTransaction>) => {
    return await api.post('/inventory/stock/adjust', data)
  },

  transferStock: async (data: StockTransferRequest) => {
    return await api.post('/inventory/stock/transfer', data)
  },

  // Transactions
  listTransactions: async (page = 1, limit = 10, filters?: any) => {
    return await api.get('/inventory/transactions', { params: { page, limit, ...filters } })
  },

  getTransaction: async (id: string) => {
    return await api.get(`/inventory/transactions/${id}`)
  },

  // Alerts
  getAlerts: async () => {
    return await api.get('/inventory/alerts')
  },

  resolveAlert: async (id: string) => {
    return await api.put(`/inventory/alerts/${id}/resolve`)
  },

  dismissAlert: async (id: string) => {
    return await api.put(`/inventory/alerts/${id}/dismiss`)
  },

  // Reports
  getLowStockReport: async (params?: any) => {
    return await api.get('/inventory/reports/low-stock', { params })
  },

  getStockValueReport: async (params?: any) => {
    return await api.get('/inventory/reports/stock-value', { params })
  },

  getMovementsReport: async (params?: any) => {
    return await api.get('/inventory/reports/movements', { params })
  },

  exportReport: async (type: string, params?: any) => {
    return await api.get(`/inventory/reports/${type}/export`, {
      params,
      responseType: 'blob',
    })
  },
}
