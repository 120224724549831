import { combineReducers } from '@reduxjs/toolkit'

import userReducer from './user/reducers'
import appReducer from './app/reducers'
import inventoryReducer from './inventory'

const rootReducer = combineReducers({
  user: userReducer,
  app: appReducer,
  inventory: inventoryReducer,
})

export default rootReducer
