import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState, LayoutType, SelectedStore } from './types'

const initialState: AppState = {
  isAuth: false,
  isAppLoading: false,
  token: {
    accessToken: '',
    refreshToken: '',
  },
  stores: [],
  selectedStore: {
    id: '',
    store_id: '',
    is_active: false,
    role: 0,
    store_domain: '',
    store_type: '',
    metaData: {},
  },
  layout: {
    type: LayoutType.FULL,
    isMobile: false,
  },
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAuth(state, action: PayloadAction<boolean>) {
      state.isAuth = action.payload
    },
    setApploading(state, action: PayloadAction<boolean>) {
      state.isAppLoading = action.payload
    },
    setToken(state, action: PayloadAction<AppState['token']>) {
      state.token = action.payload
    },
    setStores(state, action: PayloadAction<AppState['stores']>) {
      state.stores = action.payload
    },
    setSelectedStore(state, action: PayloadAction<SelectedStore>) {
      state.selectedStore = action.payload
    },
    setAppState(state, action: PayloadAction<AppState>) {
      return { ...state, ...action.payload }
    },
    setLayoutType(state, action: PayloadAction<LayoutType>) {
      state.layout.type = action.payload
    },
    setIsMobile(state, action: PayloadAction<boolean>) {
      state.layout.isMobile = action.payload
    },
    resetApp(state) {
      return { ...state, ...initialState }
    },
  },
})

export const { setToken, setAppState, setAuth, setSelectedStore, resetApp, setApploading, setLayoutType } =
  appSlice.actions
export default appSlice.reducer
