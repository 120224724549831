import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserState } from './types'

const initialState: UserState = {
  id: '',
  name: '',
  email: '',
  phone: '',
  role: '',
  phone_code: '',
  user_verified: false,
  email_verified: false,
  is_active: false,
  phone_verified: false,
  bio: '',
  display_name: '',
  profile_pic_url: '',
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserState>) {
      state = action.payload
      return { ...state, ...action.payload }
    },
    resetUser(state) {
      return { ...state, ...initialState }
    },
  },
})

export const { setUser, resetUser } = userSlice.actions
export default userSlice.reducer
