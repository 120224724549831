import React from 'react'
import { Button, Row, Col, Switch, Drawer, Divider, message } from 'antd'
import { GlobalOutlined, CloseCircleFilled, ShoppingCartOutlined, ShoppingOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { getAppState } from '../../../redux/app/actions'
import StoreQRCode from '../../../components/StoreQrCode'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATHS } from '../../../routes/constants'

interface Props {
  isOpen: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setOpen: (e: any) => void
  url?: string
}

const storeFrontUrl = process.env.REACT_APP_STOREFRONT_APP || 'https://shopap.in/'

const QuickActionDrawer: React.FC<Props> = ({ isOpen, setOpen, url }) => {
  const selectedStore = useSelector(getAppState).selectedStore
  const storeUrl = url ? url : `${storeFrontUrl}/${selectedStore.store_domain}`
  const navigate = useNavigate()

  const visitStore = () => {
    window.open(storeUrl, '_blank')
  }

  const handleStoreStatusChange = (checked: boolean) => {
    // Add your API call here to switch the store status (online/offline)
    message.success(`Store is now ${checked ? 'Online' : 'Offline'}`)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCreateProduct = (e: any) => {
    navigate(ROUTE_PATHS.QUICK_CREATE_PRODUCT)
    setOpen(e)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCreateOrder = (e: any) => {
    navigate(ROUTE_PATHS.QUICK_CREATE_ORDER)
    setOpen(e)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCreateCategory = (e: any) => {
    navigate(ROUTE_PATHS.QUICK_CREATE_CATEGORY)
    setOpen(e)
  }

  return (
    <Drawer
      title="Quick Store Settings"
      open={isOpen}
      closeIcon={<CloseCircleFilled />}
      onClose={setOpen}
      placement="right"
      size="large"
      footer={[
        <Button key="close" onClick={setOpen}>
          Close
        </Button>,
      ]}
      extra={[
        <Switch
          key="store-status"
          checkedChildren="Online"
          unCheckedChildren="Offline"
          defaultChecked
          onChange={handleStoreStatusChange}
        />,
      ]}
    >
      <Row>
        <Col span={24}>
          <StoreQRCode url={storeUrl} />
        </Col>
      </Row>

      <Divider style={{ margin: '8px 0px' }} type="horizontal" orientation="left" orientationMargin={0}>
        Stores Settings{' '}
      </Divider>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <p> </p>
        </Col>
        <Col span={12}>
          <Button icon={<GlobalOutlined />} type="primary" block onClick={visitStore}>
            Visit Store
          </Button>
        </Col>
      </Row>
      <Divider style={{ margin: '8px 0px' }} type="horizontal" orientation="left" orientationMargin={0}>
        Quick Actions{' '}
      </Divider>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Button icon={<ShoppingOutlined />} type="default" block onClick={handleCreateProduct}>
            Quick Create Product
          </Button>
        </Col>
        <Col span={12}>
          <Button icon={<ShoppingCartOutlined />} type="default" block onClick={handleCreateOrder}>
            Quick Create Order
          </Button>
        </Col>
        <Col span={12}>
          <Button icon={<GlobalOutlined />} type="default" block onClick={handleCreateCategory}>
            Quick Create Category
          </Button>
        </Col>
      </Row>
    </Drawer>
  )
}

export default QuickActionDrawer
