/* eslint-disable @typescript-eslint/no-explicit-any */
// src/redux/inventory/slice.ts

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { inventoryApi } from '../../pages/Catalogue/Inventory/api'
import { message } from 'antd'
import {
  InventoryItem,
  InventoryTransaction,
  StockTransferRequest,
  AlertResponse,
} from '../../pages/Catalogue/Inventory/types'

interface InventoryState {
  items: InventoryItem[]
  selectedItem: InventoryItem | null
  transactions: InventoryTransaction[]
  alerts: AlertResponse[]
  reports: {
    lowStock: any
    stockValue: any
    movements: any
  }
  loading: boolean
  error: string | null
  pagination: {
    current: number
    pageSize: number
    total: number
  }
}

const initialState: InventoryState = {
  items: [],
  selectedItem: null,
  transactions: [],
  alerts: [],
  reports: {
    lowStock: null,
    stockValue: null,
    movements: null,
  },
  loading: false,
  error: null,
  pagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
}

// Async Thunks
export const fetchInventory = createAsyncThunk(
  'inventory/fetchInventory',
  async ({ page, limit, filters }: { page: number; limit: number; filters?: any }) => {
    try {
      return await inventoryApi.listInventory(page, limit, filters)
    } catch (error: any) {
      message.error('Failed to fetch inventory')
      throw error
    }
  },
)

export const deleteInventory = createAsyncThunk('inventory/deleteInventory', async (id: string) => {
  try {
    await inventoryApi.deleteInventory(id)
    message.success('Inventory deleted successfully')
    return id
  } catch (error: any) {
    message.error('Failed to delete inventory')
    throw error
  }
})

export const transferStock = createAsyncThunk('inventory/transferStock', async (data: StockTransferRequest) => {
  try {
    const response = await inventoryApi.transferStock(data)
    message.success('Stock transferred successfully')
    return response
  } catch (error: any) {
    message.error('Failed to transfer stock')
    throw error
  }
})

export const getAlerts = createAsyncThunk('inventory/getAlerts', async () => {
  try {
    return await inventoryApi.getAlerts()
  } catch (error: any) {
    message.error('Failed to fetch alerts')
    throw error
  }
})

export const resolveAlert = createAsyncThunk('inventory/resolveAlert', async (id: string) => {
  try {
    await inventoryApi.resolveAlert(id)
    message.success('Alert resolved')
    return id
  } catch (error: any) {
    message.error('Failed to resolve alert')
    throw error
  }
})

export const dismissAlert = createAsyncThunk('inventory/dismissAlert', async (id: string) => {
  try {
    await inventoryApi.dismissAlert(id)
    message.success('Alert dismissed')
    return id
  } catch (error: any) {
    message.error('Failed to dismiss alert')
    throw error
  }
})

export const getLowStockReport = createAsyncThunk('inventory/getLowStockReport', async (params?: any) => {
  try {
    return await inventoryApi.getLowStockReport(params)
  } catch (error: any) {
    message.error('Failed to fetch low stock report')
    throw error
  }
})

export const getStockValueReport = createAsyncThunk('inventory/getStockValueReport', async (params?: any) => {
  try {
    return await inventoryApi.getStockValueReport(params)
  } catch (error: any) {
    message.error('Failed to fetch stock value report')
    throw error
  }
})

export const getMovementsReport = createAsyncThunk('inventory/getMovementsReport', async (params?: any) => {
  try {
    return await inventoryApi.getMovementsReport(params)
  } catch (error: any) {
    message.error('Failed to fetch movements report')
    throw error
  }
})

export const exportReport = createAsyncThunk(
  'inventory/exportReport',
  async ({ type, params }: { type: string; params?: any }) => {
    try {
      const response = await inventoryApi.exportReport(type, params)
      const url = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${type}-report.xlsx`)
      document.body.appendChild(link)
      link.click()
      link.remove()
      message.success('Report exported successfully')
      return true
    } catch (error: any) {
      message.error('Failed to export report')
      throw error
    }
  },
)

export const adjustStock = createAsyncThunk(
  'inventory/adjustStock',
  async (data: {
    inventory_id: string
    product_id: string
    quantity: number
    transaction_type: string
    unit_cost: number
    notes?: string
  }) => {
    try {
      const sendData: Partial<InventoryTransaction> = {
        inventory_id: data.inventory_id,
        product_id: data.product_id,
        quantity: data.quantity,
        transaction_type: data.transaction_type as 'adjustment',
        unit_cost: data.unit_cost,
      }
      const response = await inventoryApi.adjustStock(sendData)
      message.success('Stock adjusted successfully')
      return response
    } catch (error: any) {
      message.error(error.message || 'Failed to adjust stock')
      throw error
    }
  },
)

const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload
    },
    clearSelectedItem: state => {
      state.selectedItem = null
    },
  },
  extraReducers: builder => {
    builder
      // Fetch Inventory
      .addCase(fetchInventory.pending, state => {
        state.loading = true
      })
      .addCase(fetchInventory.fulfilled, (state, action) => {
        state.loading = false
        state.items = action.payload.data
        state.pagination = {
          current: action.payload.page,
          pageSize: action.payload.limit,
          total: action.payload.total,
        }
      })
      .addCase(fetchInventory.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || null
      })

      // Delete Inventory
      .addCase(deleteInventory.fulfilled, (state, action) => {
        state.items = state.items.filter(item => item.id !== action.payload)
      })

      // Get Alerts
      .addCase(getAlerts.fulfilled, (state, action) => {
        state.alerts = action.payload
      })

      // Resolve Alert
      .addCase(resolveAlert.fulfilled, (state, action) => {
        state.alerts = state.alerts.filter(alert => alert.id !== action.payload)
      })

      // Dismiss Alert
      .addCase(dismissAlert.fulfilled, (state, action) => {
        state.alerts = state.alerts.filter(alert => alert.id !== action.payload)
      })

      // Reports
      .addCase(getLowStockReport.fulfilled, (state, action) => {
        state.reports.lowStock = action.payload
      })
      .addCase(getStockValueReport.fulfilled, (state, action) => {
        state.reports.stockValue = action.payload
      })
      .addCase(getMovementsReport.fulfilled, (state, action) => {
        state.reports.movements = action.payload
      })
      // Adjust Stock
      .addCase(adjustStock.pending, state => {
        state.loading = true
      })
      .addCase(adjustStock.fulfilled, state => {
        state.loading = false
      })
      .addCase(adjustStock.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || null
      })
  },
})

export const { setSelectedItem, clearSelectedItem } = inventorySlice.actions
export default inventorySlice.reducer
