export const ROUTE_PATHS = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  CREATE_STORE: '/create-store',

  //Catalogue

  //Products
  PRODUCTS: '/catalogue/products',
  PRODUCT_CREATE: '/catalogue/product/create',
  PRODUCT_EDIT: '/catalogue/product/edit',
  PRODUCT_DETAILS: '/catalogue/product',

  //Category
  CATEGORIES: '/catalogue/categories',
  CATEGORY_CREATE: '/catalogue/category/create',
  CATEGORY_EDIT: '/catalogue/category/edit',
  CATEGORY_DETAILS: '/catalogue/category',

  //Inventory
  INVENTORY: '/catalogue/inventory',
  INVENTORY_ADD: '/catalogue/inventory/add',
  INVENTORY_EDIT: '/catalogue/inventory/edit',
  INVENTORY_DETAILS: '/catalogue/inventory',

  INVENTORY_STOCK: '/catalogue/inventory/stock',
  INVENTORY_MOVEMENTS: '/catalogue/inventory/movements',
  INVENTORY_ALERTS: '/catalogue/inventory/alerts',
  INVENTORY_REPORTS: '/catalogue/inventory/reports',

  //Media
  MEDIA: '/media',
  ADD_MEDIA: '/media/add',

  //Settings
  SETTINGS: '/settings',

  //Customers
  CUSTOMERS: '/customers',
  CUSTOMER_CREATE: '/customer/create',
  CUSTOMER_EDIT: '/customer/edit',
  CUSTOMER_DETAILS: '/customer/:id',
  CUSTOMER_ORDERS: '/customer/:id/orders',

  // CUSTOMER_GROUPS: '/customer-groups',
  // CUSTOMER_GROUP_CREATE: '/customer-group/create',
  // CUSTOMER_GROUP_EDIT: '/customer-group/edit',
  // CUSTOMER_GROUP_DETAILS: '/customer-group/:id',

  //Sales

  //Orders
  ORDERS: '/sales/orders',
  ORDER_CREATE: '/sales/order/create',
  ORDER_EDIT: '/sales/order/edit',
  ORDER_DETAILS: '/sales/order/:id',

  //Abandoned Carts
  ABANDONED_CARTS: '/sales//abandoned-carts',
  ABANDONED_CART_DETAILS: '/sales/abandoned-cart/:id',

  //Reports
  REPORTS_SALES: '/reports/sales',
  REPORTS_PRODUCTS: '/reports/products',
  REPORTS_TRAFFIC: '/reports/traffic',

  //Teams
  TEAMS: '/teams',

  //Profile
  PROFILE: '/profile',

  //Store Reviews
  STORE_REVIEWS: '/store-reviews',

  //Notifications
  NOTIFICATIONS: '/notifications',

  //Payments
  PAYMENTS: '/payments',

  //Wallet
  WALLET: '/wallet',

  //Quick Menu
  QUICK_CREATE_PRODUCT: '/quick-create/product',
  QUICK_CREATE_ORDER: '/quick-create/order',
  QUICK_CREATE_CATEGORY: '/quick-create/category',

  //Auth
  LOGOUT: '/logout',
  AUTH: '/auth',
}
