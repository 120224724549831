import { useEffect, useState } from 'react'

const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(window.matchMedia('(max-width: 767px)').matches)

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 767px)')
    const handleResize = (event: MediaQueryListEvent) => {
      setIsMobile(event.matches)
    }

    mediaQuery.addEventListener('change', handleResize)

    return () => {
      mediaQuery.removeEventListener('change', handleResize)
    }
  }, [])

  return isMobile
}

export default useIsMobile
