import { AppState } from './types'
// import { Dispatch } from 'redux'
import store from '../store'

export const getAppAuth = (): AppState['isAuth'] => {
  return store.getState().app.isAuth
}

export const getUserToken = (): AppState['token'] => {
  return store.getState().app.token
}

export const getUserStores = (): AppState['stores'] => {
  return store.getState().app.stores
}

export const getUserSelectedStore = (): AppState['selectedStore'] => {
  return store.getState().app.selectedStore
}

export const getAppLayout = (): AppState['layout'] => {
  return store.getState().app.layout
}

export const getAppState = (): AppState => {
  return store.getState().app
}

export const getAppLoader = (): AppState['isAppLoading'] => {
  return store.getState().app.isAppLoading
}

// //thunk
// export const setAuth = (isAuth: boolean) => (dispatch: Dispatch) => {
//   dispatch({ type: 'app/setAuth', payload: isAuth })
// }
