import React, { useRef } from 'react'
import { Modal, QRCode, Button, Col, Row } from 'antd'
import { CloseCircleOutlined, GlobalOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { getAppState } from '../../redux/app/actions'

interface Props {
  isModalVisible: boolean
  setIsModalVisible: (isModalVisible: boolean) => void
  url?: string
}

const storeFrontUrl = process.env.REACT_APP_STOREFRONT_APP || 'https://shopap.in/'

const StoreQRCodeModal: React.FC<Props> = ({ isModalVisible, setIsModalVisible, url }) => {
  const selectedStore = useSelector(getAppState).selectedStore
  const storeUrl = url ? url : `${storeFrontUrl}/${selectedStore.store_domain}`
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const qrRef = useRef<any>(null)

  // const downloadQRCode = () => {
  //   if (qrRef.current) {
  //     const canvas = qrRef.current.querySelector('canvas')
  //     if (canvas) {
  //       const url = canvas.toDataURL()
  //       const a = document.createElement('a')
  //       a.download = 'QRCode.png'
  //       a.href = url
  //       document.body.appendChild(a)
  //       a.click()
  //       document.body.removeChild(a)
  //     }
  //   }
  // }

  const visitStore = () => {
    window.open(storeUrl, '_blank')
  }

  return (
    <Modal
      title="Store QR Code"
      open={isModalVisible}
      closable={false}
      onCancel={() => setIsModalVisible(false)}
      footer={[
        <Row key={'qrmodal'}>
          <Col span={24}>
            <Button
              key="visit"
              icon={<GlobalOutlined />}
              type="primary"
              onClick={visitStore}
              style={{ marginRight: '10px' }}
            >
              Visit Store
            </Button>
            {/* <Button
              key="download"
              icon={<DownloadOutlined />}
              type="primary"
              onClick={downloadQRCode}
              style={{ marginRight: '10px' }}
            >
              Download QR
            </Button> */}
            <Button
              key="close"
              icon={<CloseCircleOutlined />}
              danger
              type="default"
              onClick={() => setIsModalVisible(false)}
            >
              Close
            </Button>
          </Col>
        </Row>,
      ]}
    >
      <div
        ref={qrRef}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <QRCode
          value={storeUrl}
          size={250}
          style={{ marginBottom: 16 }}
          icon="https://res.cloudinary.com/dkvcuqr2o/image/upload/v1726815885/shopap/shopap-logo-transparent_ywuldo.png"
          iconSize={80}
          type="canvas"
        />
        <p style={{ marginTop: '20px', textAlign: 'center', fontWeight: 'bold' }}>
          Scan this QR to visit <span style={{ color: 'red' }}>{storeUrl}</span> store!
        </p>
      </div>
    </Modal>
  )
}

export default StoreQRCodeModal
