import React, { useState, useEffect } from 'react'
import {
  Layout,
  Menu,
  Button,
  Avatar,
  Badge,
  Space,
  Drawer,
  theme,
  Dropdown,
  Switch,
  Tooltip,
  message,
  ConfigProvider,
} from 'antd'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  BarChartOutlined,
  UserOutlined,
  BellOutlined,
  SettingOutlined,
  LogoutOutlined,
  QrcodeOutlined,
  TeamOutlined,
  MoneyCollectOutlined,
  ScanOutlined,
  ContactsOutlined,
  CarryOutOutlined,
  ShopOutlined,
  CameraOutlined,
  ControlOutlined,
  AppstoreOutlined,
  MonitorOutlined,
  OrderedListOutlined,
  TagOutlined,
  DownOutlined,
  AccountBookOutlined,
} from '@ant-design/icons'
import { ROUTE_PATHS } from '../../routes/constants'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getAppState } from '../../redux/app/actions'
import { setAppState, setApploading } from '../../redux/app/reducers'
import { setUser } from '../../redux/user/reducers'
import StoreQRCodeModal from '../../components/Modals/StoreQRCodeModal'
import QuickActionDrawer from './components/QuickActionDrawer'
import BrandLogo from '../../components/BrandLogo'
import useIsMobile from '../../hooks/useIsMobile'
import api from '../../utils/api'
import { setAuthTokens, logoutUser } from '../../utils/auth'
import { AppState, LayoutType, Store } from '../../redux/app/types'
import { selectUser } from '../../redux/user/actions'

const { Header, Sider, Content } = Layout
const { SubMenu } = Menu

interface Props {
  children: React.ReactNode
}

interface MenuItem {
  key: string
  icon?: React.ReactNode
  label: string
  path?: string
  badge?: number
  children?: MenuItem[]
}

const storeFrontUrl = process.env.REACT_APP_STOREFRONT_APP || 'https://shopap.in/'

const SidebarLayout: React.FC<Props> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false)
  const [isDarkTheme, setIsDarkTheme] = useState(false)
  const [qrModalVisible, setQrModalVisible] = useState(false)
  const [quickActionDrawerVisible, setQuickActionDrawerVisible] = useState(false)

  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { token } = theme.useToken()
  const isMobile = useIsMobile()

  const appState = useSelector(getAppState)
  const { selectedStore, stores } = appState
  const storeUrl = `${storeFrontUrl}${selectedStore?.store_domain}`
  const isStoreSelected = selectedStore?.id
  const profilePic = useSelector(selectUser)?.profile_pic_url

  useEffect(() => {
    if (isMobile) {
      setCollapsed(true)
    }
  }, [isMobile])

  const handleLogout = async () => {
    try {
      await logoutUser()
      navigate(ROUTE_PATHS.HOME)
    } catch (error) {
      message.error('Logout failed. Please try again.')
    }
  }

  const handleStoreSwitch = async (store: Store) => {
    try {
      dispatch(setApploading(true))
      const response = await api.post('/auth/switch-store', {
        store: store.store_id,
      })

      setAuthTokens(response.access_token, response.refresh_token)

      const newAppState: AppState = {
        isAuth: true,
        isAppLoading: false,
        token: {
          accessToken: response.access_token,
          refreshToken: response.refresh_token,
        },
        selectedStore: response.selected_store,
        stores: response.stores,
        layout: {
          type: LayoutType.SIDEBAR,
          isMobile: isMobile,
        },
      }

      dispatch(setAppState(newAppState))
      dispatch(setUser(response.user))

      message.success(`Switched to ${store.store_domain}`)
      navigate(ROUTE_PATHS.DASHBOARD)
    } catch (error) {
      message.error(error instanceof Error ? error.message : 'Failed to switch store')
    } finally {
      dispatch(setApploading(false))
    }
  }

  const handleMenuClick = (path: string) => {
    navigate(path)
    if (isMobile) {
      setCollapsed(true)
    }
    // setCollapsed(true)
  }

  const handleCancel = () => {
    setQrModalVisible(false)
  }
  const hideDrawer = () => {
    setQuickActionDrawerVisible(false)
  }

  const profileMenu = (
    <Menu>
      <Menu.Item key="profile" icon={<UserOutlined />} onClick={() => navigate(ROUTE_PATHS.PROFILE)}>
        Profile
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="theme" icon={<Switch size="small" checked={isDarkTheme} onChange={setIsDarkTheme} />}>
        {!isDarkTheme ? 'Dark Mode' : 'Light Mode'}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  )

  const storeMenu = (
    <Menu>
      {stores &&
        stores.map(store => (
          <Menu.Item key={store.id} onClick={() => handleStoreSwitch(store)} disabled={store.id === selectedStore?.id}>
            <Space align="center">
              <Avatar size="small" icon={<ShopOutlined />} />
              <span>{store.store_domain}</span>
            </Space>
          </Menu.Item>
        ))}
    </Menu>
  )

  const menuItems = [
    { key: 'dashboard', icon: <HomeOutlined />, label: 'Dashboard', path: ROUTE_PATHS.DASHBOARD },
    {
      key: 'catalogue',
      icon: <ShoppingOutlined />,
      label: 'Catalogue',
      children: [
        { key: 'products', icon: <AppstoreOutlined />, label: 'Products', path: ROUTE_PATHS.PRODUCTS },
        { key: 'categories', icon: <TagOutlined />, label: 'Categories', path: ROUTE_PATHS.CATEGORIES },
        { key: 'Inventory', icon: <ScanOutlined />, label: 'Inventory', path: ROUTE_PATHS.INVENTORY },
      ],
    },
    {
      key: 'sales',
      icon: <CarryOutOutlined />,
      label: 'Sales',
      children: [
        { key: 'orders', icon: <OrderedListOutlined />, label: 'Orders', path: ROUTE_PATHS.ORDERS },
        {
          key: 'abandoned-carts',
          icon: <ShoppingCartOutlined />,
          label: 'Abandoned',
          path: ROUTE_PATHS.ABANDONED_CARTS,
        },
      ],
    },

    {
      key: 'payments',
      icon: <AccountBookOutlined />,
      label: 'Payments',
      path: ROUTE_PATHS.PAYMENTS,
    },
    // {
    //   key: 'campaigns',
    //   icon: <SoundOutlined />,
    //   label: 'Campaigns',
    //   children: [
    //     { key: 'WA Campaigns', icon: <WhatsAppOutlined />, label: 'WA Ads', path: '/campaigns/wa-campaigns' },
    //     { key: 'SMS Campaigns', icon: <SendOutlined />, label: 'SMS Ads', path: '/campaigns/sms-campaigns' },
    //     { key: 'Coupons', icon: <BarChartOutlined />, label: 'Coupons', path: '/campaigns/coupons' },
    //     { key: 'Promotions', icon: <ProductOutlined />, label: 'Promotions', path: '/campaigns/promotions' },
    //   ],
    // },
    { key: 'Media', icon: <CameraOutlined />, label: 'Media', path: ROUTE_PATHS.MEDIA },
    { key: 'Reviews', icon: <CameraOutlined />, label: 'Reviews', path: ROUTE_PATHS.STORE_REVIEWS },
    {
      key: 'reports',
      icon: <BarChartOutlined />,
      label: 'Reports',
      children: [
        { key: 'sales', icon: <MoneyCollectOutlined />, label: 'Sales', path: ROUTE_PATHS.REPORTS_SALES },
        // { key: 'analytics', icon: <MonitorOutlined />, label: 'Analytics', path: '/reports/analytics' },
        { key: 'traffic', icon: <MonitorOutlined />, label: 'Traffic', path: ROUTE_PATHS.REPORTS_TRAFFIC },
        { key: 'products', icon: <AppstoreOutlined />, label: 'Products', path: ROUTE_PATHS.REPORTS_PRODUCTS },
      ],
    },
    // {
    //   key: 'analytics',
    //   icon: <BarChartOutlined />,
    //   label: 'Analytics',
    //   children: [
    //     { key: 'sales', icon: <MoneyCollectOutlined />, label: 'Sales', path: '/analytics/sales' },
    //     { key: 'traffic', icon: <MonitorOutlined />, label: 'Analytics', path: '/analytics/analytics' },
    //   ],
    // }
    { key: 'customers', icon: <ContactsOutlined />, label: 'Customers', path: ROUTE_PATHS.CUSTOMERS },

    // { key: 'integrations', icon: <ApiOutlined />, label: 'Integrations', path: '/integrations' },
    // { key: 'vendors', icon: <GroupOutlined />, label: 'Vendors', path: '/vendors' },
    { key: 'Teams', icon: <TeamOutlined />, label: 'Teams', path: ROUTE_PATHS.TEAMS },
    {
      key: 'settings',
      icon: <SettingOutlined />,
      label: 'Settings',
      path: ROUTE_PATHS.SETTINGS,
    },
  ]

  const renderMenuItem = (item: MenuItem) => {
    if (item.children) {
      return (
        <SubMenu
          key={item.key}
          icon={item.icon}
          title={
            <Space>
              {item.label}
              {item.badge && <Badge count={item.badge} size="small" />}
            </Space>
          }
        >
          {item.children.map(child => renderMenuItem(child))}
        </SubMenu>
      )
    }

    return (
      <Menu.Item key={item.path || item.key} icon={item.icon} onClick={() => item.path && handleMenuClick(item.path)}>
        <Space>
          {item.label}
          {item.badge && <Badge count={item.badge} size="small" />}
        </Space>
      </Menu.Item>
    )
  }

  const sidebarContent = (
    <>
      {!isMobile ? (
        <div
          style={{
            height: 64,
            display: 'flex',
            alignItems: 'center',
            padding: '5',
            borderBottom: `1px solid ${token.colorBorder}`,
          }}
        >
          <BrandLogo collapsed={collapsed} />
          <Button
            type="text"
            icon={
              collapsed ? (
                <MenuUnfoldOutlined style={{ fontSize: '24px', fontWeight: 800 }} />
              ) : (
                <MenuFoldOutlined style={{ fontSize: '24px', fontWeight: 600 }} />
              )
            }
            onClick={() => setCollapsed(!collapsed)}
          />
        </div>
      ) : null}

      <Menu
        mode="inline"
        selectedKeys={[location.pathname]}
        style={{
          flex: 1,
          borderRight: 0,
          backgroundColor: isDarkTheme ? '#001529' : token.colorBgBase,
        }}
      >
        {menuItems.map(renderMenuItem)}
      </Menu>
    </>
  )

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkTheme ? [theme.darkAlgorithm] : [theme.defaultAlgorithm],
        token: {
          colorPrimary: token.colorPrimary,
        },
      }}
    >
      <Layout style={{ minHeight: '100vh', scrollbarWidth: 'thin', scrollbarColor: 'unset' }}>
        {!isMobile && (
          <Sider
            trigger={null}
            collapsed={collapsed}
            theme={isDarkTheme ? 'dark' : 'light'}
            defaultCollapsed={isMobile}
            onCollapse={setCollapsed}
            width={250}
            collapsible
            style={{
              overflow: 'hidden',
              overflowY: 'auto',
              height: '100vh',
              position: 'fixed',
              left: 0,
              top: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              scrollbarWidth: 'thin',
              scrollbarColor: 'unset',
            }}
          >
            {sidebarContent}
          </Sider>
        )}

        <Layout
          style={{
            marginLeft: isMobile ? 0 : collapsed ? 80 : 250,
            transition: 'all 0.2s',
          }}
        >
          <Header
            style={{
              padding: '0 16px',
              display: 'flex',
              alignItems: 'center',
              maxHeight: 64,
              backgroundColor: isDarkTheme ? '#001529' : token.colorBgBase,
              justifyContent: !isMobile ? 'flex-end' : 'space-between',
              position: 'fixed',
              width: isMobile ? '100%' : `calc(100% - ${collapsed ? 80 : 250}px)`,
              height: 64,
              zIndex: 1000,
              transition: 'all 0.2s',
              boxShadow: '0 1px 2px rgba(0, 0, 0, 0.03)',
            }}
          >
            {isMobile ? (
              <Space size={0} align="center">
                <Button
                  type="text"
                  icon={
                    collapsed ? (
                      <MenuUnfoldOutlined style={{ fontSize: '24px', fontWeight: 800 }} />
                    ) : (
                      <MenuFoldOutlined style={{ fontSize: '24px', fontWeight: 600 }} />
                    )
                  }
                  onClick={() => setCollapsed(!collapsed)}
                />

                <BrandLogo collapsed={collapsed} />
              </Space>
            ) : null}

            <Space size={16} align="center">
              {stores && stores.length > 1 && isStoreSelected && (
                <Dropdown overlay={storeMenu} trigger={['click']}>
                  <Button type="text">
                    <Space>
                      <Avatar size="small" icon={<ShopOutlined />} />
                      <span>{selectedStore?.store_domain}</span>
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              )}

              <Tooltip title="Quick Actions">
                <Button type="text" icon={<ControlOutlined />} onClick={() => setQuickActionDrawerVisible(true)} />
              </Tooltip>

              <Tooltip title="Store QR">
                <Button type="text" icon={<QrcodeOutlined />} onClick={() => setQrModalVisible(true)} />
              </Tooltip>

              <Badge count={0}>
                <Button
                  type="text"
                  icon={<BellOutlined />}
                  onClick={() => {
                    navigate(ROUTE_PATHS.NOTIFICATIONS)
                  }}
                />
              </Badge>

              <Dropdown overlay={profileMenu} trigger={['click']}>
                <Avatar style={{ cursor: 'pointer' }} src={profilePic} icon={<UserOutlined />} />
              </Dropdown>
            </Space>
          </Header>

          <Content
            style={{
              margin: '88px 24px 16px',
              minHeight: '100vh',
            }}
          >
            {children}
          </Content>
        </Layout>

        {isMobile && (
          <Drawer
            extra={
              <Button
                type="text"
                icon={
                  collapsed ? (
                    <MenuUnfoldOutlined style={{ fontSize: '24px', fontWeight: 800 }} />
                  ) : (
                    <MenuFoldOutlined style={{ fontSize: '24px', fontWeight: 600 }} />
                  )
                }
                onClick={() => setCollapsed(!collapsed)}
              />
            }
            placement="left"
            closeIcon={null}
            title={<BrandLogo collapsed={collapsed} />}
            onClose={() => setCollapsed(true)}
            open={!collapsed}
            width={300}
            style={{ backgroundColor: isDarkTheme ? '#001529' : token.colorBgBase }}
            bodyStyle={{ padding: 0 }}
          >
            {sidebarContent}
          </Drawer>
        )}

        <StoreQRCodeModal isModalVisible={qrModalVisible} setIsModalVisible={handleCancel} url={storeUrl} />

        <QuickActionDrawer isOpen={quickActionDrawerVisible} setOpen={hideDrawer} />
      </Layout>
    </ConfigProvider>
  )
}

export default SidebarLayout
